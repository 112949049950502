import React from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import Img from "gatsby-image"
import Packages from "../components/Packages";
import SEO from "../components/seo";

export default ({data: {datoCmsServicespage: p}}) => {
  const serviceStyle = "font-title text-4xl my-1 tracking-wide";
  const headingStyle = "font-title font-bold text-4xl uppercase mt-8 mb-2 text-center tracking-wider";
  return <Layout>
    <SEO title='Services' seo={p.seoMetaTags}/>
    <h1 className={headingStyle}>Services we offer</h1>
    <div className='font-sans font-light text-center md:mb-16' dangerouslySetInnerHTML={{__html: p.description}}/>
    <section className="flex flex-wrap md:my-16">
      <Img
        style={{minWidth: 300}}
        className='flex-1' fluid={p.image.fluid}/>
      <div className="flex flex-col justify-center ml-4 mr-16">
        <div className={serviceStyle}>Hair</div>
        <div className={serviceStyle}>makeup &</div>
        <div className={serviceStyle}>fashion</div>
      </div>
    </section>
    <section className="flex flex-wrap justify-between">
      <div className="flex-1 flex flex-col mx-2 md:mx-16">
        <h1 className={headingStyle}>Virtual Styling</h1>
        <div className='font-sans font-light mt-4 text-center'
             dangerouslySetInnerHTML={{__html: p.descriptiononline}}/>
      </div>
      <div className="flex-1 flex flex-col mx-2 md:mx-16">
        <h1 className={headingStyle}>Personal Shopping</h1>
        <div className='font-sans font-light mt-4 text-center'
             dangerouslySetInnerHTML={{__html: p.descriptionshopping}}/>
      </div>
    </section>
    <section className="flex flex-wrap my-4 md:my-16">
      <Img className='md:w-1/2 w-full' fluid={p.imagemembership.fluid}/>
      <div className="md:w-1/2 bg-yellow-700 flex flex-col justify-center px-2 md:px-16 py-2">
        <h1 className='font-sans text-3xl uppercase md:mt-8 mb-2 x tracking-wide text-white'>Exclusive
          memberships</h1>
        <div className='font-sans font-light md:mt-4 text-white'
             dangerouslySetInnerHTML={{__html: p.descriptionmembership}}/>
      </div>
    </section>
    <section>
      <h1
        className='font-sans uppercase font-light tracking-wide text-center md:text-left mx-2 md:mx-16 italic text-4xl text-purple-700'>Come
        explore with us!</h1>
    </section>
    <Packages/>
  </Layout>;
}

export const query = graphql`
    query Services {
        datoCmsServicespage {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            image {
                fluid(maxWidth: 1000, imgixParams: {fit: "crop", w:"1000", h:"180"}) {
                    ...GatsbyDatoCmsFluid
                }
            }
            description
            descriptiononline
            descriptionmembership
            descriptionshopping
            imagemembership {
                fluid(maxWidth: 700, imgixParams: {fit: "crop", w:"700", h:"300"}) {
                    ...GatsbyDatoCmsFluid
                }
            }
        }
    }

`